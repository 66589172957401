import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dialogVisible-info" }
const _hoisted_2 = { class: "dialogVisible-content" }
const _hoisted_3 = { class: "content-title" }
const _hoisted_4 = { class: "content-table" }
const _hoisted_5 = { class: "table-th" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "red-text"
}
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { key: 3 }
const _hoisted_10 = { key: 4 }
const _hoisted_11 = { class: "table-tb" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 2 }
const _hoisted_15 = { key: 3 }
const _hoisted_16 = {
  key: 4,
  class: "buy"
}
const _hoisted_17 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.dialogVisibleFlag,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dialogVisibleFlag) = $event)),
    title: "",
    width: "500px",
    "destroy-on-close": "",
    center: "",
    "show-close": false
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_17, [
        _createVNode(_component_el_button, {
          class: "content-close",
          onClick: _ctx.close
        }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [
            _createTextVNode("取消")
          ])),
          _: 1
        }, 8, ["onClick"]),
        (_ctx.tradingData.buyTitle === '改单确认')
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              loading: _ctx.buyLoading,
              class: "content-confirm",
              onClick: _ctx.confirmChange,
              type: "primary"
            }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode("确定")
              ])),
              _: 1
            }, 8, ["loading", "onClick"]))
          : _createCommentVNode("", true),
        (_ctx.tradingData.buyTitle !== '改单确认')
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 1,
              loading: _ctx.buyLoading,
              class: "content-confirm",
              onClick: _ctx.confirm,
              type: "primary"
            }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode("确定")
              ])),
              _: 1
            }, 8, ["loading", "onClick"]))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.tradingData.buyTitle ? _ctx.tradingData.buyTitle : "订单确认"), 1),
          _cache[7] || (_cache[7] = _createElementVNode("span", { class: "line" }, null, -1)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _cache[1] || (_cache[1] = _createElementVNode("span", null, "账户", -1)),
              _cache[2] || (_cache[2] = _createElementVNode("span", null, "订单类型", -1)),
              _cache[3] || (_cache[3] = _createElementVNode("span", null, "方向", -1)),
              _cache[4] || (_cache[4] = _createElementVNode("span", null, "名称", -1)),
              _cache[5] || (_cache[5] = _createElementVNode("span", null, "代码", -1)),
              (_ctx.tradingData.stockIcon === 'TW')
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, "期限"))
                : _createCommentVNode("", true),
              (_ctx.tradingData.stockIcon === 'TW' && _ctx.isAfter17())
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, "下一个交易日收盘前"))
                : _createCommentVNode("", true),
              (_ctx.tradingData.stockIcon === 'US')
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, "盘前盘后"))
                : _createCommentVNode("", true),
              (
                _ctx.tradingData.entrustPropValue !== 'MO' &&
                _ctx.tradingData.entrustPropValue !== 'AMO'
              )
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, "价格"))
                : _createCommentVNode("", true),
              _cache[6] || (_cache[6] = _createElementVNode("span", null, "数量", -1)),
              (
                _ctx.tradingData.entrustPropValue !== 'MO' &&
                _ctx.tradingData.entrustPropValue !== 'AMO'
              )
                ? (_openBlock(), _createElementBlock("span", _hoisted_10, "金额"))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("span", null, "现金账户(" + _toDisplayString(_ctx.tradingData.fundAccount) + ")", 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.tradingData.entrustProp), 1),
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.tradingData.entrustBs === '买入' ? 'buy' : 'sell')
              }, _toDisplayString(_ctx.tradingData.entrustBs), 3),
              _createElementVNode("span", null, _toDisplayString(_ctx.tradingData.stockName), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.tradingData.stockCode) + "." + _toDisplayString(_ctx.tradingData.stockIcon), 1),
              (_ctx.tradingData.stockIcon === 'TW')
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, "当日有效"))
                : _createCommentVNode("", true),
              (_ctx.tradingData.stockIcon === 'TW' && _ctx.isAfter17())
                ? (_openBlock(), _createElementBlock("span", _hoisted_13))
                : _createCommentVNode("", true),
              (_ctx.tradingData.stockIcon === 'US')
                ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.preMarketFlag ? "允许" : "不允许"), 1))
                : _createCommentVNode("", true),
              (
                _ctx.tradingData.entrustPropValue !== 'MO' &&
                _ctx.tradingData.entrustPropValue !== 'AMO'
              )
                ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.tradingData.price), 1))
                : _createCommentVNode("", true),
              _createElementVNode("span", null, _toDisplayString(_ctx.numFormat(_ctx.tradingData.entrustAmount, 0)), 1),
              (
                _ctx.tradingData.entrustPropValue !== 'MO' &&
                _ctx.tradingData.entrustPropValue !== 'AMO'
              )
                ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.tradingData.amount), 1))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}