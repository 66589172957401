import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dialogVisible-info" }
const _hoisted_2 = { class: "dialogVisible-content" }
const _hoisted_3 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.dialogVisibleFlag,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dialogVisibleFlag) = $event)),
    title: "",
    width: "600px",
    "before-close": _ctx.handleClose,
    "destroy-on-close": "",
    center: "",
    "show-close": false
  }, {
    footer: _withCtx(() => [
      _createElementVNode("span", _hoisted_3, [
        _createVNode(_component_el_button, {
          class: "content-close",
          onClick: _ctx.close
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("取消")
          ])),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_el_button, {
          class: "content-confirm",
          type: "primary",
          onClick: _ctx.confirm
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("确定")
          ])),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "content-title" }, " 提示 ", -1)),
          _createElementVNode("div", null, " 因您下单的股数按" + _toDisplayString(_ctx.title) + "拆分后会存在碎股，请问是否需要将碎股去掉，取整数？ ", 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "before-close"]))
}