
import { defineComponent, computed } from 'vue'
import { numFormat } from '@/utils/num_format'
import dayjs from 'dayjs'
export default defineComponent({
  props: {
    title: { type: String, default: '订单确认' },
    dialogVisible: { type: Boolean, default: false },
    tradingData: { type: Object, default: () => ({}) },
    buyLoading: { type: Boolean, default: false },
    preMarketFlag: { type: Boolean, default: true },
    price: { type: String, default: '' }
  },
  emits: ['update:dialogVisible', 'buyNow', 'buyNowChange'],
  setup(props, { emit }) {
    console.log(props)
    const dialogVisibleFlag = computed({
      get() {
        return props.dialogVisible
      },
      set(val) {
        emit('update:dialogVisible', val)
      }
    })
    const close = () => {
      emit('update:dialogVisible', false)
    }
    const confirm = () => {
      emit('buyNow')
    }
    const confirmChange = () => {
      emit('buyNowChange')
    }

    // 判断当前时间是否为17:00后
    const isAfter17 = (): boolean => {
      const currentTime = dayjs()
      const targetTime = dayjs().hour(17).minute(0).second(0)
      return currentTime.isAfter(targetTime)
    }
    return {
      close,
      confirm,
      confirmChange,
      numFormat,
      dialogVisibleFlag,
      isAfter17
    }
  }
})
