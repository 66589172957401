
import info1 from '@/assets/images/home/infonew.png'
import { computed, defineComponent, reactive, watch, toRefs } from 'vue'
import { tHeaderListDetail, tHeaderListTWDetail } from '../data'
import { numFormat } from '@/utils/num_format'
import checkDetailCom from '@/views/trade/realTimeData/checkDetail/index.vue'
import orderHistory from './orderHistory.vue'
import { nanoid } from 'nanoid'
import { useStore } from '@/store'
import * as quotType from '@/store/modules/quot/mutations-type'
import { ElMessageBox } from 'element-plus'
import { Market } from '@/hooks/useStock'
export default defineComponent({
  components: { checkDetailCom, orderHistory },
  props: {
    dialogVisible: { type: Boolean, default: false },
    dataForm: { type: Object, default: () => ({}) },
    tableData: { type: Array, default: () => [] },
    activeName: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    tableLoading: { type: Boolean, default: false },
    totalMarketValue: { type: String, default: '--' }
  },
  emits: [
    'update:dialogVisible',
    'typeValue',
    'checkRowClick',
    'changOrderForm',
    'againOrderForm',
    'cancelOrderSuccess'
  ],
  setup(props, { emit }) {
    const store: any = useStore()
    const state = reactive({
      activeNameValue: 'HK',
      switchPositionValue: 'CZ',
      clockTab: 'HK',
      height: window.innerHeight - 400 < 467 ? 467 : window.innerHeight - 400,
      aMarker: process.env.VUE_APP_H5_A_SWITCH,
      tableDataL: 0, // 默认单数
      infoDialog: false,
      info2Dialog: false
    })
    const dialogVisibleFlag = computed({
      get() {
        return props.dialogVisible
      },
      set(val) {
        emit('update:dialogVisible', val)
      }
    })
    watch(
      () => props.activeName,
      (newValue: any) => {
        if (newValue === 'A' || newValue === 'SZ' || newValue === 'SH') {
          state.activeNameValue = 'AG'
        } else {
          state.activeNameValue = newValue
        }
        if (newValue === 'HK') {
          state.clockTab = 'HK'
        } else if (newValue === 'US') {
          state.clockTab = 'US'
        } else if (newValue === 'TW') {
          state.clockTab = 'TW'
        } else {
          state.clockTab = 'AG'
        }
      },
      { deep: true }
    )
    // 切换 tab
    const handleClick = (val: any) => {
      state.activeNameValue = val.props.name
      // store.commit(quotType.GET_ORDERS, nanoid())
      if (val.props.name === 'US') {
        if (state.clockTab !== 'US') {
          emit('typeValue', 'US')
          state.clockTab = 'US'
        }
      } else if (val.props.name === 'HK') {
        if (state.clockTab !== 'HK') {
          emit('typeValue', 'HK')
          state.clockTab = 'HK'
        }
      } else if (val.props.name === 'TW') {
        if (state.clockTab !== 'TW') {
          emit('typeValue', 'TW')
          state.clockTab = 'TW'
        }
      } else {
        if (state.clockTab !== 'AG') {
          emit('typeValue', 'A')
          state.clockTab = 'AG'
        }
      }
    }

    const refreshClick = () => {
      if (state.activeNameValue === 'US') {
        emit('typeValue', 'US')
      } else if (state.activeNameValue === 'HK') {
        emit('typeValue', 'HK')
      } else if (state.activeNameValue === 'TW') {
        emit('typeValue', 'TW')
      } else {
        emit('typeValue', 'A')
      }
    }
    const handleClickCZ = (val: any) => {
      console.log(val.props.name)
      if (val.props.name === 'LS') {
        return
      }
      store.commit(quotType.GET_ORDERS, nanoid())
    }
    // 总市值 title
    const totalTitle = (value: string) => {
      if (value === 'HK') {
        return '港股总市值'
      } else if (value === 'US') {
        return '美股总市值'
      } else if (value === 'TW') {
        return '台股总市值'
      } else {
        return 'A股总市值'
      }
    }
    // 改单
    const changOrderForm = (val: any) => {
      emit('changOrderForm', val)
    }
    // 重新下单
    const againOrderForm = (val: any) => {
      emit('againOrderForm', val)
    }
    // 撤单成功
    const cancelOrderSuccess = (val: any) => {
      emit('cancelOrderSuccess', val)
    }
    const handleClose = () => {
      emit('update:dialogVisible', false)
    }
    const close = () => {
      emit('update:dialogVisible', false)
    }
    const confirm = () => {
      // activeName.value = 'HK'
      emit('update:dialogVisible', false)
    }
    const rowClick = (row: any) => {
      emit('checkRowClick', row)
    }
    // 现金可提免责声明
    const infoFetchBalance = () => {
      state.infoDialog = true
    }
    // 最大购买力免责声明
    const infoEnableBalance = () => {
      state.info2Dialog = true
    }

    const renderTableHeader = computed(() => {
      if (props.activeName === Market.TW) {
        return tHeaderListTWDetail
      }
      return tHeaderListDetail
    })

    const formatBuyDisplay = computed(() => {
      if ([Market.A, Market.SZ, Market.SH].includes(Market[props.activeName])) {
        return props.dataForm?.enableBalanceDisplay !== '--'
          ? numFormat(props.dataForm?.enableBalanceDisplay, 2)
          : props.dataForm?.enableBalanceDisplay
      } else {
        return numFormat(props.dataForm?.multiCurrencyBuyPower, 2)
      }
    })
    return {
      ...toRefs(state),
      handleClose,
      confirm,
      close,
      dialogVisibleFlag,
      handleClick,
      numFormat,
      infoFetchBalance,
      infoEnableBalance,
      info1,
      tHeaderListDetail,
      handleClickCZ,
      changOrderForm,
      againOrderForm,
      cancelOrderSuccess,
      rowClick,
      totalTitle,
      refreshClick,
      formatBuyDisplay,
      renderTableHeader
    }
  }
})
