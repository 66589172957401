
import { defineComponent, reactive, ref, toRefs, watch } from 'vue'
import { tHeaderListHistory } from '../data'
import dayjs from 'dayjs'
import * as gql from '@/graphql'
import { useQuery } from '@vue/apollo-composable'
import storage, { StorageType } from '@/utils/storage'
export default defineComponent({
  props: {
    activeNameValue: { type: String, default: '' },
    switchPositionValue: { type: String, default: '' }
  },
  setup (props) {
    const state = reactive({
      value1: [dayjs(new Date()), dayjs(new Date())],
      height: window.innerHeight - 440 < 460 ? 400 : window.innerHeight - 500,
      tableData: [],
      total: 0,
      page: 1,
      limit: 5,
      fundAccount: storage.rcGetItem(StorageType.local, 'userInfo'),
      tableLoading: false,
      activeName: 'HK'
    })
    const hisDeliverListParams = ref({
      filter: {
        fundAccount: state.fundAccount,
        startDate: Number(dayjs(new Date()).format('YYYYMMDD')),
        endDate: Number(dayjs(new Date()).format('YYYYMMDD')),
        pageNo: state.page,
        pageSize: state.limit,
        exchangeType: state.activeName
      }
    })
    const { refetch, onResult } = useQuery(
      gql.hisDeliverList,
      hisDeliverListParams,
      {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true
      }
    )
    watch(
      () => props.activeNameValue,
      (newValue: any) => {
        if (newValue === 'A' || newValue === 'SZ' || newValue === 'SH') {
          state.activeName = 'AG'
        } else {
          state.activeName = newValue
        }
        const params = {
          filter: {
            fundAccount: state.fundAccount,
            startDate: Number(dayjs(state.value1[0]).format('YYYYMMDD')),
            endDate: Number(dayjs(state.value1[1]).format('YYYYMMDD')),
            pageNo: state.page,
            pageSize: state.limit,
            exchangeType: newValue
          }
        }
        refetch(params)
      },
      { deep: true }
    )
    // 处理数据
    const dealData = (arr: any) => {
      for (let i = 0; i < arr.length; i++) {
        arr[i].tradeDetailed.forEach((v: any) => {
          arr[i][v.title] = v.value
          arr[i].tradeNo = arr[i].tradeDetail[0].tradeNo
        })
      }
      return arr
    }
    onResult((res) => {
      if (res.data) {
        const { HisDeliver } = res.data
        const arr = HisDeliver?.data?.rows || []
        state.tableData = dealData(arr)
        state.tableLoading = false
        state.total = HisDeliver.data.totalCount
      }
    })
    // 查找订单
    const searchOrder = () => {
      console.log(state.value1)
      if (!state.value1) {
        return
      }
      state.tableLoading = true
      hisDeliverListParams.value = {
        filter: {
          fundAccount: state.fundAccount,
          startDate: Number(dayjs(state.value1[0]).format('YYYYMMDD')),
          endDate: Number(dayjs(state.value1[1]).format('YYYYMMDD')),
          pageNo: state.page,
          pageSize: state.limit,
          exchangeType: state.activeName
        }
      }
    }
    // 更改页码
    const getData = () => {
      state.tableLoading = true
      hisDeliverListParams.value = {
        filter: {
          fundAccount: state.fundAccount,
          startDate: Number(dayjs(state.value1[0]).format('YYYYMMDD')),
          endDate: Number(dayjs(state.value1[1]).format('YYYYMMDD')),
          pageNo: state.page,
          pageSize: state.limit,
          exchangeType: state.activeName
        }
      }
    }
    // 重置
    const reset = () => {
      // dayjs(new Date()), dayjs(new Date())
      const params = {
          filter: {
            fundAccount: state.fundAccount,
            startDate: Number(dayjs(new Date()).format('YYYYMMDD')),
            endDate: Number(dayjs(new Date()).format('YYYYMMDD')),
            pageNo: 1,
            pageSize: state.limit,
            exchangeType: state.activeName
          }
        }
        refetch(params)
    }
    watch(
      () => props.switchPositionValue,
      (newValue: any) => {
        if (newValue === 'LS') {
          refetch(hisDeliverListParams.value)
        }
      },
      { deep: true }
    )
    return {
      ...toRefs(state),
      getData,
      searchOrder,
      reset,
      tHeaderListHistory
    }
  }
})
