export const tHeaderList = Object.freeze([
  {
    type: 'selection',
    prop: 'selectStatus',
    width: '90',
    fixed: 'left',
    label: '全选'
  },
  // { label: '序列号', type: 'index', width: '80' },
  { label: '排序', type: 'sort', iconWidth: 14, iconHeight: 14, width: '80' },
  {
    label: '姓名/账户',
    prop: '',
    type: 'twoLines',
    width: '120px',
    propList: [{ prop: 'nameCn' }, { prop: 'fundAccount' }]
  },
  { label: '总资产', prop: 'assetDisplayNF', type: '', width: '', class: 'line-right' },
  { label: '购买力', prop: 'enableBalanceDisplayN', type: 'specialTip', width: '' },
  {
    label: '下单数量',
    prop: 'num',
    type: 'addSub',
    width: '170px',
    iconWidth: 16,
    iconHeight: 16,
    btnTypeSub: 'sub',
    btnTypeAdd: 'add',
    btnTypeInput: 'inputBlur'
  },
  {
    label: '持仓股票/数量',
    prop: 'enName',
    type: 'twoLines',
    width: '128px',
    class: 'right',
    align: 'right',
    propList: [{ prop: 'stockName' }, { prop: 'currentAmount' }]
  }
])
export const tableData = [
  // {
  //   date: '腾讯控股1',
  //   name: 'TomTomTo',
  //   account: '2458895',
  //   address: '1000股',
  //   HK: 'HK',
  //   num: 0,
  //   input: '123',
  //   id: '1',
  //   customerAccount: 120,
  //   power: 500,
  //   selectStatus: false,
  //   indexSel: ''
  // },
  // {
  //   date: '腾讯控股2',
  //   name: 'jaf',
  //   account: '24588994',
  //   address: '1000股',
  //   HK: 'US',
  //   num: 0,
  //   input: '',
  //   id: '12',
  //   customerAccount: 220,
  //   power: 5000
  // },
  // {
  //   date: '腾讯控股3',
  //   name: 'ccv',
  //   account: '24588994',
  //   address: '1000股',
  //   HK: 'HK',
  //   num: 0,
  //   input: '',
  //   id: '123',
  //   customerAccount: 320,
  //   power: 50
  // },
  // {
  //   date: '腾讯控股4',
  //   name: 'ssd',
  //   account: '24588994',
  //   address: '1000股',
  //   HK: 'HK',
  //   num: 0,
  //   input: '',
  //   id: '1234',
  //   customerAccount: 120,
  //   power: 5
  // },
  // {
  //   date: '腾讯控股5',
  //   name: 'tts',
  //   account: '24588994',
  //   address: '1000股',
  //   HK: 'HK',
  //   num: 0,
  //   input: '',
  //   id: '12345',
  //   customerAccount: 0,
  //   power: 5005
  // },
  // {
  //   date: '腾讯控股6',
  //   name: 'rs1',
  //   account: '24588994',
  //   address: '1000股',
  //   HK: 'HK',
  //   num: 0,
  //   input: '',
  //   id: '123456',
  //   customerAccount: 120,
  //   power: 500
  // }
]
// 选项(港股)
export const listHK = [
  {
    label: '限价单 (增强) ',
    value: 'ELO',
    valueOf: 'ELO1'
  },
  {
    label: '市价单',
    value: 'MO',
    valueOf: 'MO1'
  },
  {
    label: '竞价限价单',
    value: 'ALO',
    valueOf: 'ALO1'
  },
  {
    label: '竞价市价单',
    value: 'AMO',
    valueOf: 'AMO1'
  }
]
// 选项(美股)
export const listUS = [
  {
    label: '限价单',
    value: 'LO',
    valueOf: 'LO2'
  },
  {
    label: '市价单',
    value: 'MO',
    valueOf: 'MO2'
  }
]
// 选项(A股)
export const listA = [
  {
    label: '限价单',
    value: 'LO',
    valueOf: 'LO3'
  }
]

export const listTW = [
  {
    label: '限价单',
    value: 'LO',
    valueOf: 'LO2'
  },
  {
    label: '市价单',
    value: 'MO',
    valueOf: 'MO2'
  }
]

// 详情页表格
export const tHeaderListDetail = Object.freeze([
  { label: '代码', prop: 'stockCode', type: '', width: '' },
  { label: '名称', prop: 'stockNameGb', type: '', width: '' },
  { label: '总市值', prop: 'marketValueDisplay', type: '', width: '' },
  { label: '可用', prop: 'enableAmountDisplay', type: '', width: '' },
  { label: '持有', prop: 'currentAmountDisplay', type: '', width: '' },
  { label: '成本', prop: 'costPrice', type: '', width: '' },
  { label: '现价', prop: 'currentPriceDisplay', type: '', width: '' },
  { label: '盈亏', prop: 'profit', type: 'twoLines', width: '', propList: [{ prop: 'incomeBalanceDisplay' }, { prop: 'incomePercentDisplay' }] }
])

// 台股列表
export const tHeaderListTWDetail = Object.freeze([
  { label: '代码', prop: 'stockCode', type: '', width: '' },
  { label: '名称', prop: 'stockNameGb', type: '', width: '' },
  { label: '总市值', prop: 'marketValueDisplay', type: '', width: '' },
  { label: '可用', prop: 'enableAmountDisplay', type: '', width: '' },
  { label: '持有', prop: 'currentAmountDisplay', type: '', width: '' },
  { label: '成本', prop: 'costPrice', type: 'slot', width: '' },
  { label: '昨收价', prop: 'currentPriceDisplay', type: 'slot', width: '' },
  { label: '盈亏', prop: 'profit', type: 'twoLines', width: '', propList: [{ prop: 'incomeBalanceDisplay' }, { prop: 'incomePercentDisplay' }] }
])
// 历史成交表格
export const tHeaderListHistory = [
  { label: '订单号', prop: 'tradeNo', type: '', width: '' },
  {
    label: '方向',
    prop: '交易方向',
    type: 'renderHTML',
    width: '',
    render: (data: any) => {
      if (data) {
        return data === '买入'
          ? '<p style="color:#ffb400;font-weight:500">买入</p>'
          : '<p style="color:#386AFF;font-weight:500">卖出</p>'
      }
    }
  },
  {
    label: '股票名称/代码',
    prop: 'enName',
    type: 'twoLinesIconS',
    width: '220px',
    propList: [
      { prop: 'stockName', icon: 'exchangeType', class: 'lines-stock-name' },
      { prop: 'stockCode', class: 'lines-stock-code' },
      { prop: 'exchangeType', class: 'lines-stock-type' }
    ]
  },
  {
    label: '成交价格/数量',
    prop: 'enName',
    type: 'twoLines',
    width: '128px',
    class: 'right',
    align: 'right',
    propList: [{ prop: '成交价格' }, { prop: '成交数量' }]
  },
  {
    label: '成交金额/时间',
    prop: 'enName',
    type: 'twoLines',
    width: '120px',
    class: 'right',
    align: 'right',
    propList: [{ prop: '成交金额' }, { prop: '成交时间' }]
  },
  {
    label: '交易费/交易征费',
    prop: 'enName',
    type: 'twoLines',
    width: '130px',
    class: 'right',
    align: 'right',
    propList: [{ prop: '交易费' }, { prop: '交易征费' }]
  },
  {
    label: '佣金费/印花税',
    prop: 'enName',
    type: 'twoLines',
    width: '120px',
    class: 'right',
    align: 'right',
    propList: [{ prop: '佣金' }, { prop: '印花税' }]
  },
  {
    label: '结算费/平台使用费',
    prop: 'enName',
    type: 'twoLines',
    width: '160px',
    class: 'right',
    align: 'right',
    propList: [{ prop: '结算费' }, { prop: '平台使用费' }]
  },
  {
    label: '财汇交易征费/总成交费用',
    prop: 'enName',
    type: 'twoLines',
    width: '200px',
    class: 'right',
    align: 'right',
    propList: [{ prop: '财汇交易征费' }, { prop: '总费用' }]
  }
  // { label: '财汇交易征费', prop: 'tMoney', type: '', width: '120px' },
  // { label: '总成交费用', prop: 'tTotalCost', type: '', width: '120px' },
]
