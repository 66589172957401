import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bulk-trading" }
const _hoisted_2 = { class: "bulk-content" }
const _hoisted_3 = { class: "trading" }
const _hoisted_4 = { class: "trading-title" }
const _hoisted_5 = { class: "trading-operation" }
const _hoisted_6 = { class: "operation-name" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = ["src"]
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 2 }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "operation-fn" }
const _hoisted_13 = { class: "margin20" }
const _hoisted_14 = {
  key: 0,
  xmlns: "http://www.w3.org/2000/svg",
  width: "15px",
  viewBox: "0 0 1024 1024",
  "data-v-394d1fd8": ""
}
const _hoisted_15 = {
  key: 2,
  class: "fn-arrow"
}
const _hoisted_16 = { class: "margin20" }
const _hoisted_17 = {
  key: 0,
  xmlns: "http://www.w3.org/2000/svg",
  width: "15px",
  viewBox: "0 0 1024 1024",
  "data-v-394d1fd8": ""
}
const _hoisted_18 = { class: "cal-font" }
const _hoisted_19 = ["src"]
const _hoisted_20 = ["src"]
const _hoisted_21 = ["src"]
const _hoisted_22 = ["src"]
const _hoisted_23 = {
  key: 3,
  class: "add-num"
}
const _hoisted_24 = { class: "add-sub" }
const _hoisted_25 = { class: "add-num" }
const _hoisted_26 = { class: "add-sub" }
const _hoisted_27 = { class: "fn-number" }
const _hoisted_28 = { class: "fn-number" }
const _hoisted_29 = { class: "fn-number" }
const _hoisted_30 = { key: 4 }
const _hoisted_31 = {
  key: 0,
  class: "pre-market"
}
const _hoisted_32 = ["src"]
const _hoisted_33 = { style: {
                    color: true
                      ? 'rgba(0, 0, 0, 0.65);'
                      : 'rgba(0, 0, 0, 0.65);',
                  } }
const _hoisted_34 = { key: 5 }
const _hoisted_35 = {
  key: 0,
  class: "pre-market"
}
const _hoisted_36 = ["src"]
const _hoisted_37 = ["src"]
const _hoisted_38 = { class: "five-list" }
const _hoisted_39 = ["onClick"]
const _hoisted_40 = { style: {"color":"#386aff"} }
const _hoisted_41 = ["onClick"]
const _hoisted_42 = { style: {"color":"#ffaa00"} }
const _hoisted_43 = {
  key: 0,
  class: "operation-list five-list-td"
}
const _hoisted_44 = { class: "list-td-le" }
const _hoisted_45 = { class: "open-data" }
const _hoisted_46 = { class: "open-data" }
const _hoisted_47 = { class: "list-td-rg" }
const _hoisted_48 = { class: "open-data" }
const _hoisted_49 = { class: "open-data" }
const _hoisted_50 = {
  key: 2,
  class: "trading-btn"
}
const _hoisted_51 = { key: 1 }
const _hoisted_52 = {
  key: 2,
  class: "loading-input"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tipcom = _resolveComponent("tipcom")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_userInfo = _resolveComponent("userInfo")!
  const _component_dropdown = _resolveComponent("dropdown")!
  const _component_dropdownInput = _resolveComponent("dropdownInput")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!
  const _component_prompt = _resolveComponent("prompt")!
  const _component_unlock = _resolveComponent("unlock")!
  const _component_confirmPopup = _resolveComponent("confirmPopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.currentDisabled ? "请输入改单数据" : "请选择要交易的股票"), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (_ctx.currentDisabled)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, "方向"))
              : _createCommentVNode("", true),
            _createElementVNode("span", null, [
              _cache[26] || (_cache[26] = _createElementVNode("span", null, "类型", -1)),
              _createVNode(_component_el_popover, {
                placement: "right",
                width: 280,
                trigger: "hover",
                content: "允许: 订单将在盘前盘后、盘中时段进行对盘撮合，盘前盘后未成交订单会自动转入盘中时段。不允许: 订单仅在盘中时段进行对盘撮合。"
              }, {
                reference: _withCtx(() => [
                  _createElementVNode("img", {
                    class: "img",
                    src: _ctx.infop,
                    alt: ""
                  }, null, 8, _hoisted_8)
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_tipcom, {
                      currentItem: _ctx.currentItem.valueOf
                    }, null, 8, ["currentItem"])
                  ])
                ]),
                _: 1
              })
            ]),
            _cache[29] || (_cache[29] = _createElementVNode("span", null, "股票代码", -1)),
            _cache[30] || (_cache[30] = _createElementVNode("span", null, "最新价", -1)),
            (_ctx.marketFlag)
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, "价格"))
              : _createCommentVNode("", true),
            _cache[31] || (_cache[31] = _createElementVNode("span", null, "数量", -1)),
            _cache[32] || (_cache[32] = _createElementVNode("span", null, "金额", -1)),
            _cache[33] || (_cache[33] = _createElementVNode("span", null, "最大可买", -1)),
            _cache[34] || (_cache[34] = _createElementVNode("span", null, "最大可卖", -1)),
            (_ctx.isShowAfterQuot)
              ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                  _cache[28] || (_cache[28] = _createElementVNode("span", null, "盘前盘后", -1)),
                  _createVNode(_component_el_popover, {
                    placement: "top-start",
                    title: "盘前盘后",
                    width: 280,
                    trigger: "hover",
                    content: "允许: 订单将在盘前盘后、盘中时段进行对盘撮合，盘前盘后未成交订单会自动转入盘中时段。不允许: 订单仅在盘中时段进行对盘撮合。"
                  }, {
                    reference: _withCtx(() => [
                      _createElementVNode("img", {
                        class: "img",
                        src: _ctx.infop,
                        style: {"margin-right":"19px"},
                        alt: ""
                      }, null, 8, _hoisted_11)
                    ]),
                    default: _withCtx(() => _cache[27] || (_cache[27] = [
                      _createElementVNode("div", null, [
                        _createElementVNode("p", null, " 允许: 订单将在盘前盘后、盘中时段进行对盘撮合，盘前盘后未成交订单会自动转入盘中时段。 "),
                        _createElementVNode("p", null, "不允许: 订单仅在盘中时段进行对盘撮合。")
                      ], -1)
                    ])),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_12, [
            (_ctx.currentDisabled)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  style: _normalizeStyle({ color: _ctx.sellDirection === '1' ? '#ffb400' : '#386AFF' })
                }, _toDisplayString(_ctx.sellDirection === "1" ? "买入" : "卖出"), 5))
              : _createCommentVNode("", true),
            (!_ctx.currentDisabled)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "fn-arrow",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeType && _ctx.changeType(...args)))
                }, [
                  _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.currentItem.label), 1),
                  (_ctx.currentItem.valueOf !== 'LO3')
                    ? (_openBlock(), _createElementBlock("svg", _hoisted_14, _cache[35] || (_cache[35] = [
                        _createElementVNode("path", {
                          fill: "currentColor",
                          d: "M104.704 338.752a64 64 0 0 1 90.496 0l316.8 316.8 316.8-316.8a64 64 0 0 1 90.496 90.496L557.248 791.296a64 64 0 0 1-90.496 0L104.704 429.248a64 64 0 0 1 0-90.496z"
                        }, null, -1)
                      ])))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.currentDisabled)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.currentItem.label), 1),
                  (!_ctx.currentDisabled)
                    ? (_openBlock(), _createElementBlock("svg", _hoisted_17, _cache[36] || (_cache[36] = [
                        _createElementVNode("path", {
                          fill: "currentColor",
                          d: "M104.704 338.752a64 64 0 0 1 90.496 0l316.8 316.8 316.8-316.8a64 64 0 0 1 90.496 90.496L557.248 791.296a64 64 0 0 1-90.496 0L104.704 429.248a64 64 0 0 1 0-90.496z"
                        }, null, -1)
                      ])))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              _createElementVNode("div", null, [
                (!_ctx.obj.label)
                  ? _withDirectives((_openBlock(), _createElementBlock("input", {
                      key: 0,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.stock) = $event)),
                      onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.inputStock && _ctx.inputStock(...args))),
                      class: "input",
                      type: "text",
                      placeholder: "请输入股票名称/代码"
                    }, null, 544)), [
                      [_vModelText, _ctx.stock]
                    ])
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: _normalizeClass({ 'koa-font': _ctx.obj?.label?.length > 61 })
                }, [
                  _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.obj.label) + " " + _toDisplayString(_ctx.obj.code), 1)
                ], 2),
                (_ctx.obj.label)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      class: _normalizeClass({
                    shadow: _ctx.obj.icon === 'HK',
                    'shadow-us': _ctx.obj.icon === 'US',
                    'shadow-tw': _ctx.obj.icon === 'TW',
                    'shadow-a': _ctx.obj.icon === 'SZ' || _ctx.obj.icon === 'SH',
                  })
                    }, _toDisplayString(_ctx.obj.icon), 3))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.stock && !_ctx.currentDisabled)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _ctx.close,
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clearInput('null'))),
                    style: {"width":"10px","height":"10px","cursor":"pointer","margin":"0 17px","z-index":"200"},
                    alt: ""
                  }, null, 8, _hoisted_19))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.computedChangeColor)
              }, _toDisplayString(_ctx.quotDetail.price || "- -"), 3),
              (_ctx.obj.label && _ctx.changeFlagNum === 2)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "up-down-diamond",
                    src: _ctx.up,
                    alt: ""
                  }, null, 8, _hoisted_20))
                : _createCommentVNode("", true),
              (_ctx.obj.label && _ctx.changeFlagNum === 0)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    class: "up-down-diamond",
                    src: _ctx.down,
                    alt: ""
                  }, null, 8, _hoisted_21))
                : _createCommentVNode("", true),
              (_ctx.obj.label && _ctx.changeFlagNum === 1)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 2,
                    class: "up-down-diamond",
                    src: _ctx.diamond,
                    alt: ""
                  }, null, 8, _hoisted_22))
                : _createCommentVNode("", true),
              (_ctx.obj.label)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 3,
                    style: {"margin-right":"10px"},
                    class: _normalizeClass(_ctx.computedChangeColor)
                  }, _toDisplayString(_ctx.quotDetail.change
                    ? _ctx.changeFlagNum === 2
                      ? "+" + _ctx.quotDetail.change
                      : _ctx.quotDetail.change
                    : "0.00" || "- -"), 3))
                : _createCommentVNode("", true),
              (_ctx.obj.label)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 4,
                    class: _normalizeClass(_ctx.computedChangeColor)
                  }, _toDisplayString(_ctx.computedRiseFall || "- -"), 3))
                : _createCommentVNode("", true)
            ]),
            (_ctx.marketFlag)
              ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("span", {
                      class: "input-add input-sub",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handlerClick('subPrice')))
                    }, _cache[37] || (_cache[37] = [
                      _createElementVNode("i", { class: "iconfont icon-jianhao" }, null, -1)
                    ])),
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.price) = $event)),
                      ref: "refPrice",
                      class: "input input1",
                      type: "text",
                      onKeydown: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.keydown && _ctx.keydown(...args))),
                      onBlur: _cache[7] || (_cache[7] = ($event: any) => (
                    _ctx.price =
                      _ctx.obj.icon === 'SH' || _ctx.obj.icon === 'SZ'
                        ? _ctx.numFormat(_ctx.price, 2).replace(/,/g, '')
                        : _ctx.numFormat(_ctx.price, 3).replace(/,/g, '')
                  )),
                      placeholder: "请输入价格"
                    }, null, 544), [
                      [_vModelText, _ctx.price]
                    ]),
                    _createElementVNode("span", {
                      class: "input-add",
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.handlerClick('addPrice')))
                    }, _cache[38] || (_cache[38] = [
                      _createElementVNode("i", { class: "iconfont icon-plus" }, null, -1)
                    ]))
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("span", {
                  class: "input-add input-sub",
                  onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.handlerClick('subNum')))
                }, _cache[39] || (_cache[39] = [
                  _createElementVNode("i", { class: "iconfont icon-jianhao" }, null, -1)
                ])),
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.num) = $event)),
                  ref: "inputNumRef",
                  class: "input input1",
                  placeholder: "请输入数量",
                  autocompleted: "off",
                  autocomplete: "new-password"
                }, null, 512), [
                  [_vModelText, _ctx.num]
                ]),
                _createElementVNode("span", {
                  class: "input-add",
                  onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.handlerClick('addNum')))
                }, _cache[40] || (_cache[40] = [
                  _createElementVNode("i", { class: "iconfont icon-plus" }, null, -1)
                ]))
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.amountComputed) + " " + _toDisplayString(_ctx.amountComputed !== "- -"
                    ? _ctx.obj.icon === "HK"
                      ? "HKD"
                      : "" || _ctx.obj.icon === "US"
                      ? "USD"
                      : "" || _ctx.obj.icon === "SH"
                      ? "CNY"
                      : "" || _ctx.obj.icon === "SZ"
                      ? "CNY"
                      : "" || _ctx.obj.icon === "TW"
                      ? "TWD"
                      : ""
                    : ""), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.bigBuy), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.bigSellNum !== "- -" ? _ctx.numFormat(_ctx.bigSellNum, 0) : "- -"), 1)
            ]),
            (_ctx.isShowAllowOrder)
              ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                  (_ctx.currentDisabled)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                        _createElementVNode("img", {
                          src: _ctx.ok1,
                          alt: "",
                          style: {"display":"inline-block","margin-right":"15px","width":"20px","height":"20px"}
                        }, null, 8, _hoisted_32),
                        _createElementVNode("span", _hoisted_33, _toDisplayString(_ctx.extendedTradingF), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.isShowAllow)
              ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                  (!_ctx.currentDisabled)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                        _createElementVNode("div", {
                          onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.market(true))),
                          style: {"margin-right":"42px"}
                        }, [
                          _createElementVNode("img", {
                            src: _ctx.preMarketFlag ? _ctx.ok1 : _ctx.ok2,
                            alt: ""
                          }, null, 8, _hoisted_36),
                          _createElementVNode("span", {
                            style: _normalizeStyle({
                      color: _ctx.preMarketFlag
                        ? 'rgba(0, 0, 0, 0.65);'
                        : 'rgba(0, 0, 0, 0.65);',
                    })
                          }, "允许", 4)
                        ]),
                        _createElementVNode("div", {
                          onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.market(false)))
                        }, [
                          _createElementVNode("img", {
                            src: _ctx.preMarketFlag ? _ctx.ok2 : _ctx.ok1,
                            alt: ""
                          }, null, 8, _hoisted_37),
                          _createElementVNode("span", {
                            style: _normalizeStyle({
                      color: _ctx.preMarketFlag
                        ? 'rgba(0, 0, 0, 0.65);'
                        : 'rgba(0, 0, 0, 0.65);',
                    })
                          }, "不允许", 4)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_38, [
          _createElementVNode("div", {
            class: _normalizeClass(["operation-list", {
              'min-hei':
                _ctx.currentItem.valueOf === 'LO2' || _ctx.currentItem.valueOf === 'MO2',
            }])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.b5List, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                onClick: ($event: any) => (_ctx.selectSeller(item))
              }, [
                _createElementVNode("span", _hoisted_40, _toDisplayString(item.label), 1),
                _createElementVNode("span", {
                  class: _normalizeClass({
                  'span-red': _ctx.autoColor(item.price) === 1,
                  'span-gray': _ctx.autoColor(item.price) === 2,
                  'span-green': _ctx.autoColor(item.price) === 3,
                })
                }, _toDisplayString(item.price), 3),
                _createElementVNode("span", null, _toDisplayString(_ctx.numFormatK(item.qty)), 1)
              ], 8, _hoisted_39))
            }), 128))
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["operation-list sell-flex", {
              'min-hei':
                _ctx.currentItem.valueOf === 'LO2' || _ctx.currentItem.valueOf === 'MO2',
            }])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.s5List, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                onClick: ($event: any) => (_ctx.selectSeller(item))
              }, [
                _createElementVNode("span", _hoisted_42, _toDisplayString(item.label), 1),
                _createElementVNode("span", {
                  class: _normalizeClass({
                  'span-red': _ctx.autoColor(item.price) === 1,
                  'span-gray': _ctx.autoColor(item.price) === 2,
                  'span-green': _ctx.autoColor(item.price) === 3,
                })
                }, _toDisplayString(item.price), 3),
                _createElementVNode("span", null, _toDisplayString(_ctx.numFormatK(item.qty)), 1)
              ], 8, _hoisted_41))
            }), 128))
          ], 2)
        ]),
        (_ctx.isShowJK)
          ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
              _createElementVNode("div", _hoisted_44, [
                _createElementVNode("div", _hoisted_45, [
                  _cache[41] || (_cache[41] = _createElementVNode("span", null, "今开", -1)),
                  _createElementVNode("span", {
                    class: _normalizeClass({
                  'span-red': _ctx.autoColor(_ctx.quotDetail.open) === 1,
                  'span-gray': _ctx.autoColor(_ctx.quotDetail.open) === 2,
                  'span-green': _ctx.autoColor(_ctx.quotDetail.open) === 3,
                })
                  }, _toDisplayString(_ctx.quotDetail.open === "" ? "--" : _ctx.quotDetail.open), 3)
                ]),
                _createElementVNode("div", _hoisted_46, [
                  _cache[42] || (_cache[42] = _createElementVNode("span", null, "换手", -1)),
                  _createElementVNode("span", null, _toDisplayString((_ctx.quotDetail.turnRate
                  ? new _ctx.Decimal(_ctx.quotDetail.turnRate)
                      .mul(10000)
                      .div(100)
                      .toFixed(2, _ctx.Decimal.ROUND_DOWN) + "%"
                  : "--") === "0.00%"
                  ? "--"
                  : _ctx.quotDetail.turnRate
                  ? new _ctx.Decimal(_ctx.quotDetail.turnRate)
                      .mul(10000)
                      .div(100)
                      .toFixed(2, _ctx.Decimal.ROUND_DOWN) + "%"
                  : "--"), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_47, [
                _createElementVNode("div", _hoisted_48, [
                  _cache[43] || (_cache[43] = _createElementVNode("span", null, "最低", -1)),
                  _createElementVNode("span", {
                    class: _normalizeClass({
                  'span-red': _ctx.autoColor(_ctx.quotDetail.low) === 1,
                  'span-gray': _ctx.autoColor(_ctx.quotDetail.low) === 2,
                  'span-green': _ctx.autoColor(_ctx.quotDetail.low) === 3,
                })
                  }, _toDisplayString(_ctx.quotDetail.low), 3)
                ]),
                _createElementVNode("div", _hoisted_49, [
                  _cache[44] || (_cache[44] = _createElementVNode("span", null, "最高", -1)),
                  _createElementVNode("span", {
                    class: _normalizeClass({
                  'span-red': _ctx.autoColor(_ctx.quotDetail.high) === 1,
                  'span-gray': _ctx.autoColor(_ctx.quotDetail.high) === 2,
                  'span-green': _ctx.autoColor(_ctx.quotDetail.high) === 3,
                })
                  }, _toDisplayString(_ctx.quotDetail.high), 3)
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        _cache[46] || (_cache[46] = _createElementVNode("div", { class: "trading-show" }, null, -1)),
        (!_ctx.unlockFlag)
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 1,
              class: "unlock-button",
              type: "info",
              onClick: _ctx.unlockBuy
            }, {
              default: _withCtx(() => _cache[45] || (_cache[45] = [
                _createTextVNode("解锁交易")
              ])),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (_ctx.unlockFlag)
          ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
              (_ctx.currentDisabled)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass(_ctx.isDdisableStatus.isTrading ? '' : 'disabled'),
                    onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.clearInput('null'))),
                    style: {"background":"#efefef","color":"#394058"}
                  }, "取消改单", 2))
                : _createCommentVNode("", true),
              (!_ctx.currentDisabled)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    class: _normalizeClass(_ctx.isDdisableStatus.isTrading ? '' : 'disabled'),
                    onClick: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.bulkBuy && _ctx.bulkBuy(...args)))
                  }, "买入", 2))
                : _createCommentVNode("", true),
              (_ctx.currentDisabled)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 2,
                    class: _normalizeClass([_ctx.isDdisableStatus.isTrading ? '' : 'disabled', "span"]),
                    onClick: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.confirmChangeOrder && _ctx.confirmChangeOrder(...args)))
                  }, "确认改单", 2))
                : _createCommentVNode("", true),
              (!_ctx.currentDisabled)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 3,
                    class: _normalizeClass([_ctx.isDdisableStatus.isTrading ? '' : 'disabled', "span"]),
                    onClick: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.batchSell && _ctx.batchSell(...args)))
                  }, "卖出", 2))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_userInfo, {
        loading: _ctx.loading,
        totalMarketValue: _ctx.accList.totalMarketValue,
        onChangOrderForm: _ctx.changOrderForm,
        onAgainOrderForm: _ctx.againOrderForm,
        tableLoading: _ctx.loading,
        onCancelOrderSuccess: _ctx.cancelOrderSuccess,
        dataForm: _ctx.accList.listRight,
        "onUpdate:dataForm": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.accList.listRight) = $event)),
        activeName: _ctx.activeName,
        "onUpdate:activeName": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.activeName) = $event)),
        onTypeValue: _ctx.typeValue,
        onCheckRowClick: _ctx.checkRowClick,
        tableData: _ctx.accList.tableDataList,
        "onUpdate:tableData": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.accList.tableDataList) = $event)),
        class: "account"
      }, null, 8, ["loading", "totalMarketValue", "onChangOrderForm", "onAgainOrderForm", "tableLoading", "onCancelOrderSuccess", "dataForm", "activeName", "onTypeValue", "onCheckRowClick", "tableData"])
    ]),
    (_ctx.typeFlag)
      ? (_openBlock(), _createBlock(_component_dropdown, {
          key: 0,
          onChangeItem: _ctx.changeItem,
          changeItemIndex: _ctx.changeItemIndex,
          list: _ctx.list
        }, null, 8, ["onChangeItem", "changeItemIndex", "list"]))
      : _createCommentVNode("", true),
    (!_ctx.inputLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
          (_ctx.inputFlag)
            ? (_openBlock(), _createBlock(_component_dropdownInput, {
                key: 0,
                onSelectItem: _ctx.selectItem,
                inputFlag: _ctx.inputFlag,
                "onUpdate:inputFlag": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.inputFlag) = $event)),
                stock: _ctx.stock,
                "onUpdate:stock": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.stock) = $event)),
                associateList: _ctx.associateList
              }, null, 8, ["onSelectItem", "inputFlag", "stock", "associateList"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.inputLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
          (_ctx.inputLoading)
            ? (_openBlock(), _createBlock(_component_el_skeleton, {
                key: 0,
                rows: 3,
                animated: ""
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_prompt, {
      title: _ctx.promptTitle,
      onBrokenStock: _ctx.brokenStock,
      dialogVisible: _ctx.dialogVisible,
      "onUpdate:dialogVisible": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.dialogVisible) = $event))
    }, null, 8, ["title", "onBrokenStock", "dialogVisible"]),
    _createVNode(_component_unlock, {
      unlockDialog: _ctx.unlockDialogVisible,
      "onUpdate:unlockDialog": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.unlockDialogVisible) = $event)),
      onUnlockFlagClick: _ctx.unlockFlagClick
    }, null, 8, ["unlockDialog", "onUnlockFlagClick"]),
    _createVNode(_component_confirmPopup, {
      preMarketFlag: _ctx.preMarketFlag,
      title: _ctx.buyTitle,
      buyLoading: _ctx.buyLoading,
      onBuyNow: _ctx.buyNow,
      onBuyNowChange: _ctx.buyNowChange,
      dialogVisible: _ctx.confirmPopupFlag,
      "onUpdate:dialogVisible": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.confirmPopupFlag) = $event)),
      tradingData: _ctx.tradingData,
      price: _ctx.price
    }, null, 8, ["preMarketFlag", "title", "buyLoading", "onBuyNow", "onBuyNowChange", "dialogVisible", "tradingData", "price"])
  ]))
}