import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "order-history" }
const _hoisted_2 = { class: "history-search" }
const _hoisted_3 = { class: "search-date" }
const _hoisted_4 = { class: "history-table" }
const _hoisted_5 = { class: "history-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_FargoTable = _resolveComponent("FargoTable")!
  const _component_FargoPagination = _resolveComponent("FargoPagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[6] || (_cache[6] = _createElementVNode("span", null, "查询范围:", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_date_picker, {
          modelValue: _ctx.value1,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value1) = $event)),
          type: "daterange",
          "range-separator": "To",
          format: "YYYY-MM-DD",
          "start-placeholder": "Start date",
          "end-placeholder": "End date"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_el_button, {
          onClick: _ctx.reset,
          class: "reset s-button"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("重置")
          ])),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_el_button, {
          onClick: _ctx.searchOrder,
          class: "search s-button"
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode("查询")
          ])),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_FargoTable, {
        "table-loading": _ctx.tableLoading,
        list: _ctx.tableData,
        "onUpdate:list": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tableData) = $event)),
        "t-header-list": _ctx.tHeaderListHistory,
        height: _ctx.height
      }, null, 8, ["table-loading", "list", "t-header-list", "height"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_FargoPagination, {
        total: _ctx.total,
        page: _ctx.page,
        "onUpdate:page": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.page) = $event)),
        limit: _ctx.limit,
        "onUpdate:limit": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.limit) = $event)),
        onPagination: _ctx.getData
      }, null, 8, ["total", "page", "limit", "onPagination"])
    ])
  ]))
}