import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "dropdown-input" }
const _hoisted_2 = {
  class: "list",
  ref: "listSto",
  "infinite-scroll-distance": "150",
  "infinite-scroll-delay": "20"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _directive_infinite_scroll = _resolveDirective("infinite-scroll")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.inputFlag)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "mask",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.inputFlagC && _ctx.inputFlagC(...args)))
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_scrollbar, {
        class: "scroll-wrapper",
        height: "150",
        "min-size": "150",
        ref: "list"
      }, {
        default: _withCtx(() => [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.associateList, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                class: _normalizeClass(["dropdown-list", { 'row-next': _ctx.currentIndex === index }]),
                key: index,
                onClick: ($event: any) => (_ctx.selectItem(item))
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass({
                span: true,
                'us-span': item.icon === 'US',
                'hk-span': item.icon === 'HK',
                'a-span': item.icon === 'SH' || item.icon === 'SZ'
              })
                }, _toDisplayString(item.icon), 3),
                _createElementVNode("div", null, [
                  _createElementVNode("span", {
                    class: _normalizeClass({ 'span-label': item.label.length > 39 })
                  }, _toDisplayString(item.label), 3),
                  _createElementVNode("span", null, _toDisplayString(item.code), 1)
                ])
              ], 10, _hoisted_3))
            }), 128))
          ])), [
            [_directive_infinite_scroll, _ctx.load]
          ])
        ]),
        _: 1
      }, 512)
    ])
  ]))
}