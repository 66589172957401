import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tips-info" }
const _hoisted_2 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, "订单类型(" + _toDisplayString(_ctx.tabShow(_ctx.currentItem).type) + ")", 1),
    _createVNode(_component_el_tabs, {
      modelValue: _ctx.tradType,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tradType) = $event)),
      onTabClick: _ctx.handleClick
    }, {
      default: _withCtx(() => [
        (_ctx.tabShow(_ctx.currentItem).value === 2)
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 0,
              label: "限价单",
              name: "LO2"
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createElementVNode("p", null, " 限价单需要指定成交价格，只有达到指定价格或有更好价格时才会执行订单。 ", -1),
                _createElementVNode("p", null, "其交易时段为：", -1),
                _createElementVNode("p", null, " 盘前时段（美东时间 04:00-09:30)、盘中时段（美东时间 09:30-16:00）、盘后时段（美东时间 16:00-20:00）交易说明： ", -1),
                _createElementVNode("p", null, "美股盘前盘后时间段市场买卖档差价较大，请留意风险", -1)
              ])),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.tabShow(_ctx.currentItem).value === 2)
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 1,
              label: "市价单",
              name: "MO2"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createElementVNode("p", null, " 市价单只在盘中时段（美东时间09:30-16:00）以市场价格即时成交，其他时段不会成交。 ", -1),
                _createElementVNode("p", null, " 交易说明：市价单保证成交，但不保证成交价格，这意味着订单将可能以任何价格成交。 ", -1)
              ])),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.tabShow(_ctx.currentItem).value === 1)
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 2,
              label: "限价单 (增强) ",
              name: "ELO1"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createElementVNode("p", null, "即普通交易，以指定或者更优的价格买入或卖出股票。", -1),
                _createElementVNode("p", null, "交易时段：", -1),
                _createElementVNode("p", null, " 1、早市竞价时段下单时段：09:00-09:20 随机对盘：09:20-09:22 暂停时段：对盘完成后-09:30 ", -1),
                _createElementVNode("p", null, "2、持续交易时段上午时段：09:30-12：00 下午时段：13:00-16:00", -1),
                _createElementVNode("p", null, " 3、收市竞价交易时段下单时段：16:00-16:08 随机收市：16:08-16:10以上时间周末及节假日除外。 ", -1)
              ])),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.tabShow(_ctx.currentItem).value === 1)
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 3,
              label: "市价单",
              name: "MO1"
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createElementVNode("p", null, " 港币市价单不需要指定价格，按照市场价格进行买入或卖出委托。仅能在持续交易时间段（09:30-12:00；13:00-16:00）下单。 ", -1),
                _createElementVNode("p", null, "交易说明：", -1),
                _createElementVNode("p", null, " 1、市价单并不保证较好的执行价格，这意味着订单将可能以任何价格成交。 ", -1),
                _createElementVNode("p", null, " 2、确认提交市价单后，金马会按照买入/卖出指示以摆盘（买盘/卖盘）五档价格提交至港交所。 ", -1),
                _createElementVNode("p", null, "3、如果股票流通性较差或暂无摆盘，可能导致市价单无法成交并撤单。", -1)
              ])),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.tabShow(_ctx.currentItem).value === 1)
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 4,
              label: "竞价限价单",
              name: "ALO1"
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createElementVNode("p", null, " 股票集合竞价是将数笔报价或一时段内的全部订单报价集中在一起，根据不高于申买价和不低于申卖价的原则产生一个成交价格，且在这个价格下成交的股票数量最大，并将这个价格作为全部成交订单的交易价格。 ", -1),
                _createElementVNode("p", null, "交易时段为：", -1),
                _createElementVNode("p", null, " 1、早市竞价限价交易需在9:00-9:22下单，需要指定价格，9:15后将不能改单和撤单，9:20-9:22为随机对盘时段。 ", -1),
                _createElementVNode("p", null, " 2、收市竞价限价交易需在16：01-16:10下单，16:06后将不能改单和撤单，16:08-16:10之间随机收市。 ", -1)
              ])),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.tabShow(_ctx.currentItem).value === 1)
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 5,
              label: "竞价市价单",
              name: "AMO1"
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createElementVNode("p", null, " 股票集合竞价是将数笔报价或一时段内的全部订单报价集中在一起，根据不高于申买价和不低于申卖价的原则产生一个成交价格，且在这个价格下成交的股票数量最大，并将这个价格作为全部成交订单的交易价格。 ", -1),
                _createElementVNode("p", null, "交易时段为：", -1),
                _createElementVNode("p", null, " 1、早市竞价市价交易需在9:00-9:22下单，无需指定价格，9:15后将不能改单和撤单，9:20-9:22为随机对盘时段。 ", -1),
                _createElementVNode("p", null, " 2、收市竞价市价交易需在16：01-16:10下单，16:06后将不能改单和撤单，16:08-16:10之间随机收市。 ", -1)
              ])),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.tabShow(_ctx.currentItem).value === 3)
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 6,
              label: "限价单",
              name: "LO3"
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createElementVNode("p", null, "限价单是以特定价格或更优的价格执行买入或卖出的订单。", -1),
                _createElementVNode("p", null, "中华通交易时段为：", -1),
                _createElementVNode("p", null, "1、开市集合竞价 9:15~9:25", -1),
                _createElementVNode("p", null, "2、连续竞价 9:30~11:30 13:00~14:57", -1),
                _createElementVNode("p", null, "3、收市集合竞价 14:57~15:00)", -1)
              ])),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue", "onTabClick"])
  ]))
}