export enum Market {
  HK = 'HK',
  US = 'US',
  SH = 'SH',
  SZ = 'SZ'
}

export enum Spead {
  A = '01',
  B = '03',
  C = '04',
  D = '05',
}

export enum Direction {
  Sub,
  Add
}

export function getStkStep(originPrice: any, mkt: any, type: any, direction: Direction) {
  const price = Number(originPrice)
  if (Number.isNaN(price)) {
    return originPrice
  }
  if (mkt === Market.HK) {
    if (type === Spead.A || type === Spead.C) {
      if (price >= 0 && price < 0.25) {
        return 0.001
      } else if (price === 0.25) {
        if (direction === Direction.Add) {
          return 0.005
        } else {
          return 0.001
        }
      } else if (price > 0.25 && price < 0.5) {
        return 0.005
      } else if (price === 0.5) {
        if (direction === Direction.Add) {
          return 0.01
        } else {
          return 0.005
        }
      } else if (price > 0.5 && price < 10) {
        return 0.01
      } else if (price === 10) {
        if (direction === Direction.Add) {
          return 0.02
        } else {
          return 0.01
        }
      } else if (price > 10 && price < 20) {
        return 0.02
      } else if (price === 20) {
        if (direction === Direction.Add) {
          return 0.05
        } else {
          return 0.02
        }
      } else if (price > 20 && price < 100) {
        return 0.05
      } else if (price === 100) {
        if (direction === Direction.Add) {
          return 0.1
        } else {
          return 0.05
        }
      } else if (price > 100 && price < 200) {
        return 0.1
      } else if (price === 200) {
        if (direction === Direction.Add) {
          return 0.2
        } else {
          return 0.1
        }
      } else if (price > 200 && price < 500) {
        return 0.2
      } else if (price === 500) {
        if (direction === Direction.Add) {
          return 0.5
        } else {
          return 0.2
        }
      } else if (price > 500 && price < 1000) {
        return 0.5
      } else if (price === 1000) {
        if (direction === Direction.Add) {
          return 1
        } else {
          return 0.5
        }
      } else if (price > 1000 && price < 2000) {
        return 1
      } else if (price === 2000) {
        if (direction === Direction.Add) {
          return 2
        } else {
          return 1
        }
      } else if (price > 2000 && price < 5000) {
        return 2
      } else if (price === 5000) {
        if (direction === Direction.Add) {
          return 5
        } else {
          return 2
        }
      } else if (price > 5000 && price < 9995) {
        return 5
      } else if (price === 9995) {
        if (direction === Direction.Add) {
          return 5
        } else {
          return 5
        }
      } else {
        return 5
      }
      // if (price > 0 && price < 0.25) {
      //   return 0.001
      // } else if (price >= 0.25 && price < 0.5) {
      //   return 0.005
      // } else if (price >= 0.5 && price <= 10) {
      //   return 0.01
      // } else if (price >= 10 && price < 20) {
      //   return 0.02
      // } else if (price >= 20 && price < 100) {
      //   return 0.05
      // } else if (price >= 100 && price < 200) {
      //   return 0.1
      // } else if (price >= 200 && price < 500) {
      //   return 0.2
      // } else if (price >= 500 && price < 1000) {
      //   return 0.5
      // } else if (price >= 1000 && price < 2000) {
      //   return 1
      // } else if (price >= 2000 && price < 5000) {
      //   return 2
      // } else if (price >= 5000 && price < 9995) {
      //   return 5
      // }
    } else if (type === Spead.B) {
      return 0.05
    } else if (type === Spead.D) {
      if (price >= 0 && price < 1) {
        return 0.001
      } else if (price === 1) {
        if (direction === Direction.Add) {
          return 0.002
        } else {
          return 0.001
        }
      } else if (price > 1 && price < 5) {
        return 0.002
      } else if (price === 5) {
        if (direction === Direction.Add) {
          return 0.005
        } else {
          return 0.002
        }
      } else if (price > 5 && price < 10) {
        return 0.005
      } else if (price === 10) {
        if (direction === Direction.Add) {
          return 0.01
        } else {
          return 0.005
        }
      } else if (price > 10 && price < 20) {
        return 0.01
      } else if (price === 20) {
        if (direction === Direction.Add) {
          return 0.02
        } else {
          return 0.01
        }
      } else if (price > 20 && price < 100) {
        return 0.02
      } else if (price === 100) {
        if (direction === Direction.Add) {
          return 0.05
        } else {
          return 0.02
        }
      } else if (price > 100 && price < 200) {
        return 0.05
      } else if (price === 200) {
        if (direction === Direction.Add) {
          return 0.1
        } else {
          return 0.05
        }
      } else if (price > 200 && price < 500) {
        return 0.1
      } else if (price === 500) {
        if (direction === Direction.Add) {
          return 0.2
        } else {
          return 0.1
        }
      } else if (price > 500 && price < 1000) {
        return 0.2
      } else if (price === 1000) {
        if (direction === Direction.Add) {
          return 0.5
        } else {
          return 0.2
        }
      } else if (price > 1000 && price < 2000) {
        return 0.5
      } else if (price === 2000) {
        if (direction === Direction.Add) {
          return 1
        } else {
          return 0.5
        }
      } else if (price > 2000 && price < 9999) {
        return 1
      } else if (price === 9999) {
        if (direction === Direction.Add) {
          return 1
        } else {
          return 1
        }
      } else {
        return 1
      }
      // if (price >= 0 && price < 1) {
      //   return 0.001
      // } else if (price >= 1 && price < 5) {
      //   return 0.002
      // } else if (price >= 5 && price < 10) {
      //   return 0.005
      // } else if (price >= 10 && price < 20) {
      //   return 0.01
      // } else if (price >= 20 && price < 100) {
      //   return 0.02
      // } else if (price >= 100 && price < 200) {
      //   return 0.05
      // } else if (price >= 200 && price < 500) {
      //   return 0.1
      // } else if (price >= 500 && price < 1000) {
      //   return 0.2
      // } else if (price >= 1000 && price < 2000) {
      //   return 0.5
      // } else if (price >= 2000 && price < 9999) {
      //   return 1
      // }
    }
  } else {
    return 0.01
  }
}
