import { ref, Ref } from 'vue'
import { Market } from './useStock'
import webSocket from '@/mqtt'

type StockInfo = {
  code: string
  market: string
}

/**
 * 订阅方法
 * @param isDelayQuot 是否延时行情
 * @param AccAssetIds 个人持仓列表
 * @param stockInfo  查询个股信息
 * @returns
 */
export default function useSubQuot (isDelayQuot: Ref<boolean>, AccAssetIds: Ref<string[]>, stockInfo: Ref<StockInfo>) {
  const searchTopicArray = ref([] as string[])

  const subscribe = () => {
    const assetId = `${stockInfo.value.code}.${stockInfo.value.market}`
    // 在持仓列表里面的数据不需要重新订阅
    if (AccAssetIds.value.includes(assetId)) return

    if (isDelayQuot.value) {
      if (stockInfo.value.market === Market.HK) {
        const topic2 = `DELAY.QUOT.${stockInfo.value.market}.${stockInfo.value.code}.${stockInfo.value.market}.2`
        const topic3 = `DELAY.QUOT.${stockInfo.value.market}.${stockInfo.value.code}.${stockInfo.value.market}.3`
        searchTopicArray.value = [topic2, topic3]
        webSocket.subscribe([topic2, topic3])
      } else if (stockInfo.value.market === Market.US) {
        const topic2 = `DELAY.QUOT.${stockInfo.value.market}.${stockInfo.value.code}.${stockInfo.value.market}.2`
        const topic3 = `DELAY.QUOT.${stockInfo.value.market}.${stockInfo.value.code}.${stockInfo.value.market}.21`
        searchTopicArray.value = [topic2, topic3]
        webSocket.subscribe([topic2, topic3])
      } else {
        const topic2 = `DELAY.QUOT.ML.${stockInfo.value.market}.${stockInfo.value.code}.${stockInfo.value.market}.2`
        const topic3 = `DELAY.QUOT.ML.${stockInfo.value.market}.${stockInfo.value.code}.${stockInfo.value.market}.3`
        searchTopicArray.value = [topic2, topic3]
        webSocket.subscribe([topic2, topic3])
      }
    } else {
      if (stockInfo.value.market === Market.HK) {
        const topic2 = `QUOT.${stockInfo.value.market}.${stockInfo.value.code}.${stockInfo.value.market}.2`
        const topic3 = `QUOT.${stockInfo.value.market}.${stockInfo.value.code}.${stockInfo.value.market}.3`
        searchTopicArray.value = [topic2, topic3]
        webSocket.subscribe([topic2, topic3])
      } else if (stockInfo.value.market === Market.US) {
        const topic2 = `QUOT.${stockInfo.value.market}.${stockInfo.value.code}.${stockInfo.value.market}.2`
        const topic3 = `QUOT.${stockInfo.value.market}.${stockInfo.value.code}.${stockInfo.value.market}.21`
        searchTopicArray.value = [topic2, topic3]
        webSocket.subscribe([topic2, topic3])
      } else {
        const topic2 = `QUOT.ML.${stockInfo.value.market}.${stockInfo.value.code}.${stockInfo.value.market}.2`
        const topic3 = `QUOT.ML.${stockInfo.value.market}.${stockInfo.value.code}.${stockInfo.value.market}.3`
        searchTopicArray.value = [topic2, topic3]
        webSocket.subscribe([topic2, topic3])
      }
    }
  }

  // 取消搜索股票订阅
  const unSubscribeForSearch = () => {
    const assetId = `${stockInfo.value.code}.${stockInfo.value.market}`
    if (AccAssetIds.value.includes(assetId)) return
    webSocket.unsubscribe(searchTopicArray.value)
  }

  return {
    subscribe,
    unSubscribeForSearch
  }
}
