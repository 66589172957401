
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  ref,
  watch,
  onUnmounted
} from 'vue'
export default defineComponent({
  components: {},
  props: {
    associateList: { type: Array, default: () => [] },
    inputFlag: { type: Boolean, default: false },
    stock: { type: String, default: '' }
  },
  emits: ['selectItem', 'update:inputFlag'],
  setup (props, { emit }) {
    const list: any = ref()
    const listSto: any = ref()
    const state = reactive({
      start: 0, // 滚动过程显示的开始索引
      end: 10, // 滚动过程显示的结束索引
      scrollTop: '' as any,
      currentIndex: 0,
      mouseG: '',
      scrollTopNumber: 1 // 统计重复值用到
    })
    // 计算渲染数组
    const showList = computed(() => {
      // 打开键盘事件
      // eslint-disable-next-line
      window.addEventListener('keydown', keyUp)
      // eslint-disable-next-line
      window.addEventListener('mousewheel', handleScroll)
      return props.associateList.slice(state.start, state.end)
    })
    watch(
      () => props.stock,
      (newValue: any) => {
        console.log(newValue)
        list.value.scrollTop = 0
        state.currentIndex = 0
        state.end = 10
        state.mouseG = ''
        state.scrollTopNumber = 1
      }
    )
    // 键盘选中所选的数据
    const keyUp = (e: any) => {
      // 上键
      if (e.keyCode === 38) {
        e.preventDefault()
        if (state.currentIndex === 0) {
          list.value.scrollTop = (state.currentIndex - 4) * 72
          return
        }
        // 滚动了滚动条
        if (state.mouseG === '触发') {
          list.value.scrollTop = state.scrollTopNumber
          state.mouseG = ''
          return
        }
        if (list.value && state.mouseG !== '触发') {
          state.currentIndex--
          setTimeout(() => {
            // 计算 当处于当前页时  不需要滚动滚动条
            const dom = document.querySelector('.row-next') as any
            const positionTop = dom.getBoundingClientRect().top
            if (positionTop < 267) {
              list.value.wrap.scrollTop -= 72
              state.scrollTopNumber = list.value.wrap.scrollTop
            }
          })
        }
      }
      // 下键
      if (e.keyCode === 40) {
        // 边界情况
        if (state.currentIndex >= Number(props.associateList.length - 1)) {
          return
        }
        // 滚动了滚动条
        if (state.mouseG === '触发') {
          list.value.scrollTop = state.scrollTopNumber
          state.mouseG = ''
          return
        }
        // 测试
        state.currentIndex++
        setTimeout(() => {
          const dom = document.querySelector('.row-next') as any
            const positionTop = dom.getBoundingClientRect().top
            if (positionTop > 627) {
              list.value.scrollTop = (state.currentIndex - 5) * 72
            }
            state.scrollTopNumber = list.value.scrollTop
        })
      }
      // enter
      if (e.keyCode === 13) {
        emit('selectItem', props.associateList[state.currentIndex])
      }
    }
    // 鼠标滚动事件
    // eslint-disable-next-line
    const handleScroll = () => {
      state.mouseG = '触发'
    }
    // load 方法
    const load = () => {
      state.end += 10
    }
    const selectItem = (obj: Object) => {
      emit('selectItem', obj)
    }
    const inputFlagC = () => {
      emit('update:inputFlag', false)
    }
    onUnmounted(() => {
      window.removeEventListener('keydown', keyUp)
      window.removeEventListener('mousewheel', handleScroll)
    })
    return {
      ...toRefs(state),
      selectItem,
      inputFlagC,
      showList,
      list,
      load,
      listSto
    }
  }
})
