
import { defineComponent, reactive, toRefs, watch } from 'vue'
export default defineComponent({
  components: {},
  props: {
    list: { type: Array, default: () => [] },
    changeItemIndex: { type: Number, default: 0 }
  },
  emits: ['changeItem'],
  setup (props, { emit }) {
    const state = reactive({
      currentIndex: props.changeItemIndex
    })
    watch(
      () => props.list,
      (newValue: any) => {
        console.log(newValue, '22')
      },
      { deep: true }
    )
    const changeItem = (value: any, label: String, valueOf: String, index: any) => {
      state.currentIndex = index
      console.log(state.currentIndex)
      emit('changeItem', value, label, valueOf, index)
    }
    return {
      ...toRefs(state),
      changeItem
    }
  }
})
