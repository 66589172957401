import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dialogVisible-info" }
const _hoisted_2 = { class: "dialogVisible-content" }
const _hoisted_3 = {
  key: 1,
  class: "show-card"
}
const _hoisted_4 = { class: "card-title" }
const _hoisted_5 = { class: "card-num" }
const _hoisted_6 = { class: "card-title" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "card-num" }
const _hoisted_9 = { class: "card-title" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "card-num" }
const _hoisted_12 = { class: "card-num" }
const _hoisted_13 = {
  style: {"margin-top":"15px"},
  class: "cc-table"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!
  const _component_Refresh = _resolveComponent("Refresh")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_FargoTable = _resolveComponent("FargoTable")!
  const _component_checkDetailCom = _resolveComponent("checkDetailCom")!
  const _component_orderHistory = _resolveComponent("orderHistory")!
  const _component_tips_dialog = _resolveComponent("tips-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_tabs, {
        modelValue: _ctx.activeNameValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeNameValue) = $event)),
        onTabClick: _ctx.handleClick
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tab_pane, {
            label: "港股",
            name: "HK"
          }),
          _createVNode(_component_el_tab_pane, {
            label: "美股",
            name: "US"
          }),
          (_ctx.aMarker === 'on')
            ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                key: 0,
                label: "沪深",
                name: "AG"
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_el_tab_pane, {
            label: "台股",
            name: "TW"
          })
        ]),
        _: 1
      }, 8, ["modelValue", "onTabClick"]),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_el_skeleton, {
            key: 0,
            rows: 3,
            animated: ""
          }))
        : _createCommentVNode("", true),
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.totalTitle(_ctx.activeNameValue)), 1),
              _createElementVNode("span", _hoisted_5, _toDisplayString(!isNaN(_ctx.totalMarketValue)
              ? _ctx.totalMarketValue !== "--"
                ? _ctx.numFormat(_ctx.totalMarketValue, 2)
                : _ctx.totalMarketValue
              : "--"), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("span", _hoisted_6, [
                _createTextVNode(_toDisplayString(_ctx.activeNameValue === 'TW' ? '现金' : '现金可提'), 1),
                _createElementVNode("img", {
                  class: "img",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.infoFetchBalance && _ctx.infoFetchBalance(...args))),
                  src: _ctx.info1,
                  alt: ""
                }, null, 8, _hoisted_7),
                _createElementVNode("div", {
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.infoFetchBalance && _ctx.infoFetchBalance(...args)))
                })
              ]),
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.dataForm?.fetchBalanceDisplay !== "--"
              ? _ctx.numFormat(_ctx.dataForm?.fetchBalanceDisplay, 2)
              : _ctx.dataForm?.fetchBalanceDisplay), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("span", _hoisted_9, [
                _cache[12] || (_cache[12] = _createTextVNode("最大购买力")),
                _createElementVNode("img", {
                  class: "img",
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.infoEnableBalance && _ctx.infoEnableBalance(...args))),
                  src: _ctx.info1,
                  alt: ""
                }, null, 8, _hoisted_10),
                _createElementVNode("div", {
                  class: "title-img",
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.infoEnableBalance && _ctx.infoEnableBalance(...args)))
                })
              ]),
              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.formatBuyDisplay), 1)
            ]),
            _createElementVNode("div", null, [
              _cache[13] || (_cache[13] = _createElementVNode("span", { class: "card-title" }, "冻结资金", -1)),
              _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.dataForm.frozenBalanceDisplay !== "--"
              ? _ctx.dataForm.frozenBalance !== 0
                ? _ctx.numFormat(_ctx.dataForm?.frozenBalance, 2)
                : "0.00"
              : "--"), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", {
          class: "refresh",
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.refreshClick && _ctx.refreshClick(...args)))
        }, [
          _createVNode(_component_el_icon, null, {
            default: _withCtx(() => [
              _createVNode(_component_Refresh)
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_el_tabs, {
          modelValue: _ctx.switchPositionValue,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.switchPositionValue) = $event)),
          onTabClick: _ctx.handleClickCZ
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tab_pane, {
              label: "持仓",
              name: "CZ"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_component_FargoTable, {
                  key: _ctx.activeName,
                  "table-loading": _ctx.tableLoading,
                  list: _ctx.tableData,
                  "onUpdate:list": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.tableData) = $event)),
                  "t-header-list": _ctx.renderTableHeader,
                  height: _ctx.height,
                  onRowClick: _ctx.rowClick
                }, {
                  costPrice: _withCtx(({ row }) => [
                    _createTextVNode(_toDisplayString(_ctx.numFormat(row.costPrice, 2)), 1)
                  ]),
                  currentPriceDisplay: _withCtx(({ row }) => [
                    _createTextVNode(_toDisplayString(_ctx.numFormat(row.currentPriceDisplay, 2)), 1)
                  ]),
                  _: 1
                }, 8, ["table-loading", "list", "t-header-list", "height", "onRowClick"]))
              ]),
              _: 1
            }),
            _createVNode(_component_el_tab_pane, {
              label: '今日订单' + '(' + _ctx.tableDataL + ')',
              name: "TD"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_checkDetailCom, {
                  tableDataL: _ctx.tableDataL,
                  "onUpdate:tableDataL": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.tableDataL) = $event)),
                  onChangOrderForm: _ctx.changOrderForm,
                  onCancelOrderSuccess: _ctx.cancelOrderSuccess,
                  onAgainOrderForm: _ctx.againOrderForm,
                  activeNameValue: _ctx.activeNameValue,
                  "onUpdate:activeNameValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.activeNameValue) = $event))
                }, null, 8, ["tableDataL", "onChangOrderForm", "onCancelOrderSuccess", "onAgainOrderForm", "activeNameValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            false
              ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                  key: 0,
                  label: "历史成交",
                  name: "LS"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_orderHistory, {
                      switchPositionValue: _ctx.switchPositionValue,
                      activeNameValue: _ctx.activeNameValue
                    }, null, 8, ["switchPositionValue", "activeNameValue"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue", "onTabClick"])
      ])
    ]),
    _createVNode(_component_tips_dialog, {
      tipsDialog: _ctx.infoDialog,
      "onUpdate:tipsDialog": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.infoDialog) = $event)),
      title: "免责声明",
      btnText: "我知道了"
    }, {
      content: _withCtx(() => _cache[14] || (_cache[14] = [
        _createElementVNode("div", { class: "info-content" }, " 「现金可提」仅供参考，该数据或未能反映账户的实时现金可提的金额，以及或未包含其他影响现金可提的收入和费用，如交易费用和处理费用等。阁下任何依赖或就该数据所作的任何决定，金马证券概不负责。实际「现金可提」概以结单為準或联系客户服务部（cs@igoldhorse.com）垂询。 ", -1)
      ])),
      _: 1
    }, 8, ["tipsDialog"]),
    _createVNode(_component_tips_dialog, {
      tipsDialog: _ctx.info2Dialog,
      "onUpdate:tipsDialog": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.info2Dialog) = $event)),
      title: "免责声明",
      btnText: "我知道了"
    }, {
      content: _withCtx(() => _cache[15] || (_cache[15] = [
        _createElementVNode("div", { class: "info-content" }, " 「最大购买力」仅供参考，该数据或未能反映帐户的实时最大购买力，以及或未包含其他影响最大购买力的收入和费用等。阁下任何依赖或就该数据所作的任何决定，金马证券概不负责。请联系客户服务部（cs@igoldhorse.com）垂询实际「最大购买力」。 ", -1)
      ])),
      _: 1
    }, 8, ["tipsDialog"])
  ]))
}