
import { defineComponent, reactive, toRefs, watch } from 'vue'
import { numFormat } from '@/utils/num_format'
export default defineComponent({
  props: {
    currentItem: { type: String, default: 'ELO1' }
  },
  emits: ['update:dialogVisible', 'buyNow', 'buyNowChange'],
  setup (props, { emit }) {
    const state = reactive({
      tradType: 'ELO1'
    })
    watch(
      () => props.currentItem,
      (newValue: any) => {
        state.tradType = newValue
      },
      { deep: true }
    )
    // 显示市场的tab
    const tabShow = (val: any) => {
      if (val === 'ELO1' || val === 'MO1' || val === 'ALO1' || val === 'AMO1') {
        return { value: 1, type: '港股' }
      } else if (val === 'LO2' || val === 'MO2') {
        return { value: 2, type: '美股' }
      } else if (val === 'LO3') {
        return { value: 3, type: 'A股' }
      }
    }
    const confirm = () => {
      emit('buyNow')
    }
    const handleClick = (val: any) => {
      state.tradType = val.props.name
    }
    return {
      confirm,
      handleClick,
      numFormat,
      tabShow,
      ...toRefs(state)
    }
  }
})
